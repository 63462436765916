@import "../../../../assets/shared";

.score-label {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 2px solid $themeLightGrey;
  border-radius: 10px;
  color: $themeDarkGrey;

  &__value {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-grade {
      font-weight: bold;
      font-size: 1.3em;
    }

    &-description {
      font-weight: bold;
      font-size: 0.9em;
    }
  }

  &__value:first-child {
    align-items: flex-start;
  }

  &__value:last-child {
    align-items: flex-end;
  }
}
