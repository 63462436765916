@import "../../../../assets/shared";

.table-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__count {
    margin-left: auto;
  }

  &__clear {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    cursor: pointer;
    svg {
      color: $themeOrange;
      font-size: 1.2rem;
      margin-right: 0.2rem;
    }
    span {
      text-align: center;
      margin-bottom: 1px;
    }
  }
  
}