@import "../../../../assets/shared";

.checkout-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;

  .header {
    display: flex;
    height: 56px;
    width: 100%;
    border-bottom: 2px solid $themeGrey;
    padding: 0 40px;

    &__button {
      color: $themeBlue;
      background-color: $themeWhite;
      text-align: center;
      border: none;

      i {
        color: $themeLightBlue;
        font-size: 2em;
        vertical-align: middle;
        font-weight: bold;
      }

      span {
        font-size: 1.2em;
        font-weight: bold;
        font-family: $themeFont;
        color: $themeBlue;
        vertical-align: middle;
        padding: 15px;
      }
    }
  }

  &__card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &-title {
      font-weight: bold;
      font-size: 1.2em;
      color: $themeBlue;
      width: 70%;
      margin: 0;
    }

    &-success {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 1.2em;
      color: $themeGreen;
    }

    &-redirect {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      font-size: 0.875em;
      padding: 10px;
      margin-top: 30px;
      border-radius: 5px;
      background-color: $themeLightBlue;
      color: $themeWhite;
      width: 100%;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .container {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      width: 70%;

      #cardholder-name {
        border: 1px solid $themeGrey;
        border-radius: 10px;
        padding: 10px;
        outline: none;
      }

      .card-details {
        border: 1px solid $themeGrey;
        border-radius: 10px;
        margin-bottom: 20px;

        .card-subdetails {
          display: flex;
          flex-direction: row;
          width: 100%;
          border-top: 1px solid $themeGrey;

          #card-cvc {
            width: 50%;
            padding: 10px;
            border-left: 1px solid $themeGrey;
          }

          #card-expiry {
            width: 50%;
            padding: 10px;
          }
        }

        #card-number {
          padding: 10px;
        }
      }

      .submit-button {
        height: 50px;
        margin-top: 30px;
        width: 100%;
        border: none;
        border-radius: 5px;
        background-color: $themeLightBlue;
        color: $themeWhite;
        font-family: $themeFont;
        font-weight: 500;
        font-size: 1.2em;
      }

      .error-message {
        margin-top: 10px;
        color: red;
      }
    }

    label {
      font-family: $themeFont;
      font-size: 1.1em;
      font-weight: 500;
      margin-bottom: 5px;
      color: $themeDarkGrey;
    }

    @include for-small-desktop-up {
      width: 50%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-small-desktop-up {
      width: 50%;
    }

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        display: block;
        max-width: 220px;
        max-height: 220px;
        width: auto;
        height: auto;
        margin-left: 40px;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      flex-direction: column;

      div {
        margin-top: 20px;
        font-size: 1.2em;
        color: $themeDarkGrey;
        align-items: flex-start;

        p {
          margin: 10px;
        }

        .value {
          color: $themeBlack;
          font-weight: 600;
          font-size: 1.9em;
        }

        .description {
          font-size: 0.9em;
          color: $themeMediumGrey;
        }
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 140px;

      hr {
        border: none;
        border-left: 1px solid $themeGrey;
        height: 2vh;
        width: 1px;
        margin-left: 15px;
        margin-right: 15px;
      }

      div {
        font-size: 1em;
        color: $themeGrey;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .terms-privacy {
          width: 110px;
        }

        p {
          cursor: pointer;
        }

        img {
          cursor: pointer;
        }
      }
    }

    &-title {
      font-weight: bold;
      font-size: 1.2em;
      color: $themeBlue;
    }

    &-label {
      color: $themeBlue;
      padding: 10px 0;
    }
  }
}
