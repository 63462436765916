@import "../../assets/shared";

.flex-score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $themeLightGrey;
  color: $themeBlue;
  padding: 10px;
  border-radius: 10px;
  margin: 0 5px;
  min-width: 200px;
  max-width: 400px;
  flex: 1;

  .report {
    display: flex;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: $themeBlue;
    font-size: 1.3em;
    margin-bottom: 10px;

    &__title {
      font-family: $themeFont;
      font-weight: bold;
      text-align: center;
      margin-left: 10px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 15px;
      width: 90%;
      min-height: 200px;
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: center;
      border-radius: 5px;
      background-color: $themeWhite;

      &_image {
        width: auto;
      }

      &-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .consulting {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: $themeBlue;
    background-color: $themeWhite;
    min-height: 200px;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    min-width: 80px;
  }

  .blue {
    display: flex;
    flex: 1 1;
    background-color: $themeBlue;
    color: $themeWhite;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    border-top-left-radius: 6px;
  }

  .grey {
    color: $themeGrey;
  }

  .details {
    background-color: $themeGrey;
    flex: 1 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 6px;
  }

  .index {
    font-weight: bold;
    font-size: 2em;
    color: $themeBlue;
  }

  .__details {
    background-color: $themeLightBlue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-width: 150px;
    height: 140px;
    border-radius: 6px;

    span {
      text-align: center;
      font-size: 3em;
      font-family: $themeFont;
      color: $themeWhite;
      font-weight: bold;
      font-style: italic;
    }

    i {
      font-size: 3em;
      color: $themeWhite;
    }
  }

  .tile-link {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    font-weight: bold;
    margin-top: 10px;
    min-height: 18px;

    i {
      margin-left: 5px;
    }
  }

  .upgrade-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .consulting-upgrade-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__icons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__description {
      width: 225px;
      font-size: 1.2em;
      text-align: center;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $themeLightBlue;
      border-radius: 7px;
      color: $themeWhite;
      width: 100%;
      padding: 15px;

      span {
        font-size: 16px;
        font-family: $themeFont;
      }
    }
  }

  .sub-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $themeLightBlue;
    border-radius: 7px;
    color: $themeWhite;
    padding: 15px;
    min-width: 220px;

    span {
      font-size: 1em;
      font-family: $themeFont;
    }
  }
}
