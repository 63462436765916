@import "../../assets/shared";
/* Primary Styles */

*,
*::before,
*::after {
  box-sizing: border-box;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

.s-sidebarleft__nav {
  z-index: 1;
  position: fixed;
  left: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 60px;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  margin-top: 65px;
  background: $themeLightGrey;

  ul {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 190px;
    background: $themeWhite;

    li {
      width: 100%;
    }
  }

  @include for-tablet-landscape-up {
    width: 190px;
  }
}

.s-sidebarleft__nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 56px;
  border: none;
  font-family: $themeFont;
  font-weight: bold;
  color: $themeBlue;
  background: $themeLightGrey;
  font-size: 0.9em;

  .sub-menu {
    left: 8px;
  }

  i {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 60px;
    height: 56px;
    color: $themeLightBlue;
    font-size: 1.25em;
  }

  i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  em {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
  }
}

.s-sidebarleft__nav-link:hover,
.s-sidebarleft__nav-link.active {
  background: $themeGrey;
}
