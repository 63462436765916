@import "../../assets/shared";

.delete-button {
  border: none;
  background-color: $themeTransparent;
  font-size: 1.2em;
  color: $themeOrange;

  &:disabled {
    cursor: unset !important;

    .fa.fa-trash {
      color: $themeGrey;
    }
  }
}
