@use "../../../../assets/shared" as shared;

@import "../../../../assets/variables.scss";

$border: solid 1px $themeLightGrey;

.wizard-footer {
  padding: 13px 13px;
  background-color: transparent;
  border: $border;
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 60%;
  margin-top: 3em;
  border-radius: 1em;

  &_title {
    font-size: 1em;
    color: shared.$themeBlue;
    font-weight: bold;
    padding-right: 1em;
    padding-left: 1em;
  }
}

.first-step {
  min-width: 85% !important;
}
