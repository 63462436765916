@import "../../../../assets/shared";

.team-review {
  &__title {
    display: flex;
    border-top: 1px solid $themeGrey;
    border-bottom: 1px solid $themeGrey;
    padding: 2em 0;
    color: $themeBlue;
    font-weight: bold;
    span {
      padding-top: 1rem;
    }
  }
  &__header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 1em;
  }
  &__filters {
    display: flex;
    align-items: flex-start;
    flex: 0.7
  }
  &__search {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.4em;
    }
  }
  
  .border {
    border: 2px solid $themeLightGrey;
  }
  .border-radius-top {
    border-radius: 6px 6px 0 0 !important;
    background-color: #fff !important;
    border-bottom: none !important;
  }

  .border-radius-bottom {
    border-radius: 0px 0px 6px 6px !important;
  }
  .list-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    background-color: #fff;
  }
  .list-top-border {
    margin-top: 0.25rem;
    border-top: 2px solid #fe4a00;
    width: 100%;
  }
  .list-underline {
    border-bottom: 1px solid $themeBlue;
  }
  .list-item {
    margin: 1rem 0;
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    z-index: 1000;
    padding: 0 0.5rem;
    background-color: #fff;
    #icon-filter-unchecked {
      color: #aaa;
      font-size: 1.25rem;
    }
    #icon-filter-checked {
      color: $themeBlue;
      font-size: 1.25rem;
    }
  }
  .checkbox-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 400px;
    overflow-y: scroll;
    width: 100%;
  }
  .display-none {
    display: none;
  }
  .button-dropdown {
    border-radius: 6px !important;
    height: 3.5vh;
    background-color: $themeGrey;
    color: $themeBlue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5vw;
    text-transform: capitalize;
    box-sizing: border-box;
    #icon-filter {
      margin-right: 0.3rem;
      font-size: 1rem;
      margin-bottom: 1px;
    }
    #icon-arrow-down {
      margin-left: 2.5rem;
    }
    .disabled {
      background-color: $themeGrey !important;
      color: $themeBlue !important;
    }
  
  .filter-title {
    display: flex;
    align-self: center;
  }

  .button-apply-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    padding: 0.25rem 1rem;
    background-color: $themeBlue;
    color: #fff;
    margin-bottom: 0.5rem;
    > svg {
      margin-left: 0.5vw;
      margin-bottom: 5px;
      font-size: 1.25rem;
    }
  }
  .clear-filters {
    cursor: pointer;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    align-self: flex-start;
    .clear-icon {
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      line-height: 14px;
      border-radius: 50%;
      background-color: #fe4a00;
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      padding-bottom: 2px;
    }
  }
}
.show {
  height: 2rem;
  max-height: 2rem;
  display: flex;
  align-items: center;
  padding: 1rem;
}
// .css-j204z7-MuiFormControlLabel-root {
//   margin-right: -40px;
// }
}