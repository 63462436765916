@use "../../../../assets/shared" as shared;

@import "../../../../assets/variables.scss";

$border: solid 1px $themeLightGrey;

table.wizard-form-table {
  width: 60%;
  
    thead {
      th {
        vertical-align: middle;
        text-align: left;
        padding-bottom:0.5em;
        font-size: 1.2em;
        color: $themeBlue;
        > i {
          margin-right: 0.5rem;
        }
      }
    }
  
    tbody {
      border: $border;
      tr {
        td {
          padding: 1rem 0.5rem 0rem 0rem;
          text-align: center;
          color: $themeGrey;


          &:first-child {
            border-left: solid 1px lightgray;
            color: $themeBlue;
          }
  
          &:last-child {
            border-right: solid 1px lightgray;
            padding-right: 1rem;
          }
        }
  
        &:first-child {
          td {
            border-top: solid 1px lightgray;
  
            &:first-child {
              border-top-left-radius: 0.5rem;
            }
  
            &:last-child {
              border-top-right-radius: 0.5rem;
            }
          }
        }
  
        &:last-child {
          td {
            border-bottom: solid 1px lightgray;
            padding-bottom: 1rem;
  
            &:first-child {
              border-bottom-left-radius: 0.5rem;
            }
  
            &:last-child {
              border-bottom-right-radius: 0.5rem;
            }
          }
        }
      }
    }

    select {
      width: 100%;
      background-color: #eceef1;
      padding: 0.65rem;
      border-radius: 0.5rem;
      border: $border;
      line-height: 3rem;
    }
  }