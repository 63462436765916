@import "../../../../assets/shared";

@keyframes userFadeIn {
  from {
    opacity: 0;
    margin-top: 0;
  }

  to {
    opacity: 1;
    margin-top: 75px;
  }
}

.navbar__user {
  display: flex;
  position: relative;
  height: 100%;

  .user__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    padding: 0 10px;
    cursor: pointer;

    a {
      align-items: flex-end;
    }

    &:hover {
      background: darken($themeBlue, 2%);
    }

    .user__name {
      font-size: 1.2em;
      font-weight: bold;
      display: none;

      @include for-tablet-portrait-up {
        display: flex;
      }
    }

    .user__progress {
      font-size: 0.775em;
      display: none;

      @include for-tablet-portrait-up {
        display: flex;
      }
    }
  }

  .help__container {
    display: flex;
    align-items: center;
    padding: 20px;
    background: darken($themeBlue, 2%);
    margin-left: 3px;
    cursor: pointer;

    span {
      padding-left: 10px;
      font-size: 0.875em;
    }

    i {
      font-size: 1.2em;
    }
  }

  .settings__container {
    display: flex;
    align-items: center;
    padding: 20px;
    background: darken($themeBlue, 2%);
    margin-left: 3px;
    cursor: pointer;

    i {
      font-size: 2em;
    }
  }

  .user__menu {
    position: absolute;
    width: 150px;
    margin-top: 75px;
    border-radius: 5px;
    right: 10px;
    color: $themeWhite;
    background: $themeBlue;
    animation: userFadeIn 0.3s linear;

    @include for-tablet-portrait-up {
      width: 100%;
    }

    &:before {
      position: absolute;
      content: " ";
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $themeBlue;
      top: -7px;
      right: 15px;
    }

    .separator {
      width: 100%;
      height: 1px;
      border-top: 1px solid white;
    }

    ul {
      margin: 0;
      padding: 10px 0;
      list-style: none;

      li {
        > * {
          padding: 10px 20px;
          display: flex;
          align-items: baseline;
          cursor: pointer;

          &.logout {
            border: none;
            background: none;
            width: 100%;
            text-align: left;
            color: inherit;
            font-size: inherit;
            outline: none;
          }

          &:hover {
            background: darken($themeBlue, 3%);
          }

          > span {
            flex: 1;
          }

          > i {
            width: 15px;
          }
        }
      }
    }
  }
}
