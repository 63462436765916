@import "../../assets/shared";
@keyframes globalLoadingFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

.global-loading {
  z-index: 9999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background: $themeBlack;
  color: $themeWhite;
  animation: globalLoadingFadeIn 0.3s linear;
  .global-loading__message {
    margin-top: 15px;
    font-size: 1.5em;
  }
}
