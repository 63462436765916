@import "../../../../assets/shared";

.reports-tabbar {
  display: flex;

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    gap: 15px;
    border-bottom: 2px solid $themeBlue;
    font-family: $themeTitleFont;
    font-size: 1.2em;
  }

  li {
    flex-grow: 1;
    background-color: $themeLightGrey;
    color: $themeBlue;
    border-radius: 10px 10px 0 0;
    height: 50px;
  }

  li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    padding: 0 10px;
    text-decoration: none;
    height: 100%;
    border-radius: 10px 10px 0 0;

    span {
      padding: 0 5px;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .metric {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      height: 42px;
      width: 42px;
      color: $themeWhite;
      font-family: $themeFont;
      background-color: $themeLightBlue;
    }
  }

  li a:hover {
    background-color: $themeGrey;
    color: $themeBlue;
  }

  li.active,
  li.active a:hover {
    background-color: $themeBlue;
    color: $themeWhite;
  }
}
