@import "../../../../assets/shared";

.index-score {
  &__title {
    padding: 20px 40px 20px 40px;
    margin-top: -40px;
    min-height: 150px;
    display: flex;

    &_box {
      flex: .6;
      padding-left: 5px;
      text-align: justify;

      &__sub-box {
        display: flex;
        align-items: center;
        flex: 0.4;
      }
    }
  }

  &__average {
    &-container {
      min-width: 250px;
      display: flex;
      flex-direction: row;
      border: 2px solid $themeBlue;
      margin: 20px 0;
    }

    &-tl {
      flex: 1;
      padding: 10px;
      text-align: center;
      background-color: $themeBlue;
      font-size: 0.875em;
      font-weight: bold;

      h1 {
        color: $themeWhite !important;
      }
    }

    &-ci {
      flex: 1;
      padding: 10px;
      text-align: center;
      background-color: red;
      color: $themeBlue;
      font-size: 0.875em;
      font-weight: bold;

    }


    &-value {
      flex: 1;
      padding: 10px;
      text-align: center;
      background-color: $themeLightBlue;
      color: $themeWhite !important;
      font-size: 0.875em;
      font-weight: bold;

      h1 {
        color: $themeWhite;
        font-size: $themeFont;
        margin: 0;
      }
    }
  }

  &__options {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-width: 25%;

    &-select {
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0px 10px 0px 10px;
        font-size: 0.875em;
        font-family: $themeFont;
        font-weight: bold;
        height: 45px;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) 96% no-repeat $themeWhite;
        color: $themeBlue;
      }
    }

    &-label {
      margin-top: 20px;
      color: $themeBlue;
      font-weight: bold;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border: none;
        background-color: $themeLightGrey;
        color: $themeBlue;
        font-family: $themeFont;
        font-size: 1em;
        font-weight: bold;
        border-radius: 10px;
        width: 100%;

        span,
        i {
          padding: 5px;
        }
      }
    }
  }
}