@import '../../assets/variables.scss';

.custom-button {
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  border-style: none;
  background-color: $themeBlue;
  text-align: left;
  color: white;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  &--green {
    background-color: $themeGreen;
    min-width: 12em;
  }
  &--grey {
    background-color: $themeGrey;
    flex-direction: row-reverse;
    color: $themeBlue;
    font-size: 1.1em;
    > i {
      padding-right: 0.5em;
      font-size: 24px;
      font-weight: bold;
      margin-left: 0 !important;
      padding-bottom: 2px;
    }
  }
}
