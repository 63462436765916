@import "../../../../assets/shared";

.security-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 80px;

  &__title {
    font-size: 3em;
    font-family: $themeTitleFont;
    color: $themeBlue;
    margin-bottom: 20px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 90%;

    @include for-tablet-portrait-up {
      width: 80%;
    }

    @include for-tablet-landscape-up {
      width: 60%;
    }

    label {
      display: inline-block;
      color: $themeBlue;
      font-size: 0.875em;
      font-weight: bold;
      width: 100%;

      @include for-tablet-portrait-up {
        width: 40%;
      }
    }

    input {
      display: inline-block;
      height: 30px;
      width: 100%;
      border: 2px solid $themeLightGrey;
      border-radius: 8px;
      padding: 20px 10px 20px 10px;
      font-family: $themeFont;
      font-size: 0.875em;
      font-weight: bold;
      color: $themeBlue;

      @include for-tablet-portrait-up {
        width: 60%;
      }
    }

    &-title {
      margin-bottom: 30px;
      font-weight: bold;
      color: $themeLightBlue;
      padding: 10px 0 10px 0;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: $themeLightGrey;
    border-radius: 10px;
    padding: 20px;

    @include for-tablet-portrait-up {
      flex-wrap: nowrap;
    }

    &-label {
      flex: auto;
      font-weight: bold;
      color: $themeBlue;
      padding: 10px;
    }

    &-button {
      width: 250px;
      padding: 10px;

      .save-button {
        height: 45px;
        width: 180px;
        border-radius: 10px;
        background-color: $themeLightBlue;
        color: $themeWhite;
        font-family: $themeFont;
        font-size: 0.875em;
        font-weight: bold;
        text-align: left;
        padding-left: 10px;
        outline: 0;
        border: none;

        i {
          position: relative;
          left: 40px;
          font-size: 1em;
        }
      }
    }
  }

  .validation-message {
    height: 25px;
    font-size: 0.8em;
    color: $themeOrange;
    text-align: right;
  }

  .error-message {
    height: 30px;
    width: 90%;
    margin-top: 10px;
    color: $themeOrange;
    font-size: 0.875em;
  }
}
