@import "../../assets/shared";

.progress-bar {
  display: flex;
  flex-direction: column;

  &__label-left {
    padding-bottom: 10px;
    font-size: 0.875em;
    font-weight: bold;
    color: $themeBlue;

    span {
      font-size: 1.5em;
    }
  }

  &__bar {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 12px;
    background-color: $themeGrey;
    border-radius: 0;
  }

  &__bar-progress {
    background-color: $themeLightBlue;
    height: 12px;
    border-radius: 0;
  }

  &__bar-mask {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;

    &-gap {
      background-color: $themeTransparent;
      border-right: 2px solid $themeWhite;
      height: 12px;
      width: 10%;
    }

    &-gap:last-child {
      border: none;
    }
  }
}
