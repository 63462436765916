@import "../../../../../../assets/shared";
.comparison-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid $themeBlue;
  .item {
    width: 50%;
    padding: 20px;
    padding-bottom: 8px;
    text-align: center;
  }
  .item:first-child {
    border-right: 1px solid $themeBlue;
  }
}
.comparison-table {
  width: 100%;
  padding: 16px 0;
  .line {
    display: flex;
    flex-direction: row;
    flex: 1;
    .cel {
      width: 50%;
      &:first-child {
        margin-right: 10px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
      > div {
        margin-bottom: 20px;
      }
    }
  }
}
