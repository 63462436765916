.search {
    width: 100%;
    background-color: #eceef1;
    border-radius: 0.5rem;
    
    input {
      width: 100%;
      background-color: transparent;
      color: #6f7173;
      border: none;
      padding: 0.75rem 1rem;
      outline: none;
    }
  }