@import "../../../../assets/shared";

.reports-topic {
  padding: 20px 0;

  &__container {
    cursor: pointer;
    padding: 20px;
    border: 2px solid $themeLightGrey;
    border-radius: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__show {
    display: flex;
    flex-direction: column;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;

    &-button {
      color: $themeBlue;
      background-color: $themeLightGrey;
      font-family: $themeFont;
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      border: none;
      border-radius: 8px 8px 0 0;
      outline: 0;
      padding: 10px;

      span {
        vertical-align: middle;
        padding-right: 10px;
      }

      i {
        vertical-align: middle;
        font-size: 1.2em;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-text {
      display: flex;
      flex-direction: column;
      width: 80%;

      &-title {
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 1.2em;
      }

      &-subtitle {
        font-size: 1em;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      border: 1px solid $themeBlue;
      width: 150px;

      &-icon {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        img {
          max-height: 50px;
        }
      }

      &-value {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $themeLightBlue;
        color: $themeWhite;
        padding: 10px;
        font-size: 1.5em;
        font-style: italic;
      }
    }
  }

  &__progress {
    padding-top: 20px;
    flex-direction: column;
  }

  &__expandable {
    display: flex;
    flex-direction: column;

    &-details {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-description {
        display: flex;
        padding-right: 30px;
        width: 80%;
        flex: 1;
      }

      &-button {
        width: 20%;
        height: 40px;
        padding: 10px 15px 10px 15px;
        font-size: 1em;
        color: $themeWhite;
        background-color: $themeLightBlue;
        text-align: center;
        border: none;
        border-radius: 8px;
        font-family: $themeFont;
        outline: 0;

        span {
          vertical-align: middle;
          padding-right: 10px;
        }

        i {
          vertical-align: middle;
          font-size: 1.2em;
        }
      }
    }

    &-graphic {
      padding: 20px 0;
    }
  }
}