$themeBlue: #173b59;
$themeDarkBlue: #12304b;
$themeLightBlue: #1392d3;
$themeSkyBlue: #00bfff;
$themeGrey: #d8dde1;
$themeMediumGrey: #706f6f;
$themeDarkGrey: #4d4d4d;
$themeLightGrey: #d7d8dac7;
$themeSilver: #f9f9f9;
$themeGreen: #0ac467;
$themeLightGreen: #c0dc46;
$themeOrange: #ff4900;
$themeYellow: #ffa200;
$themeWhite: #fff;
$themeBlack: #000;
$themeTransparent: #00000000;
$themeTitleFont: "PT Serif", serif;
$themeFont: "Omnes-Regular", sans-serif, "Montserrat", sans-serif;
$shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
$boxShadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
$innerShadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
