@import "../../assets/shared";

.tabbar {
  z-index: 1;
  height: 56px;
  position: fixed;
  top: 65px;
  left: 60px;
  right: 0px;

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  li {
    flex-grow: 1;
    background-color: $themeLightGrey;
    color: $themeBlue;
    font-weight: bold;
    border-left: 4px solid $themeWhite;

    .arrow-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .arrow-down {
        display: flex;
        align-self: center;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $themeLightBlue;
      }
    }
  }

  li a {
    display: block;
    text-align: center;
    padding-top: 18px;
    text-decoration: none;
    height: 100%;

    span {
      display: none;

      @include for-tablet-portrait-up {
        display: block;
      }
    }

    i {
      @include for-tablet-portrait-up {
        display: none;
      }
    }
  }

  li a:hover {
    background-color: $themeGrey;
    color: $themeBlue;
  }

  li.active,
  li.active a:hover {
    background-color: $themeLightBlue;
    color: $themeWhite;

    span {
      display: block;
    }

    i {
      display: none;
    }
  }

  @include for-tablet-landscape-up {
    left: 190px;
  }
}
