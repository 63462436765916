@import "../../../../assets/shared";

.question-detail {
  &__container {
    padding: 15px 0;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &-question {
      font-size: 1.1em;
      font-weight: bold;
    }

    &-value {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      min-width: 55px;
      color: $themeWhite;
      font-family: $themeFont;
      font-size: 1.4em;
      font-style: italic;
      background-color: $themeLightBlue;
    }
  }

  &__expandable {
    background-color: $themeSilver;
    padding: 0 20px 20px 20px;
    border-radius: 10px;

    &-close {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;

      &-button {
        color: $themeBlue;
        background-color: $themeSilver;
        font-family: $themeFont;
        font-size: 1em;
        font-weight: bold;
        text-align: center;
        border: none;
        border-radius: 8px;
        padding: 10px;

        span {
          vertical-align: middle;
          padding-right: 10px;
        }

        i {
          vertical-align: middle;
          font-size: 1.2em;
        }
      }
    }

    &-details {
      padding: 20px 0;
    }
  }
}
