@import "../../../../assets/shared";

.urgent-actions-page {
  height: 100%;
  min-width: 1000px;
  padding: 40px;

  &__header {
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &_subheader {
        display: flex;
      }

      &_text {
        font-family: $themeTitleFont;
        color: $themeBlue;
        font-size: 3em;
      }

      &_select {
        font-size: 1em;
        color: $themeBlue;
        font-family: $themeFont;
        font-weight: bold;
        min-width: 25%;

        .dropdown-label {
          padding: 10px 0;
        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 0px 10px 0px 10px;
          font-size: 0.875em;
          font-family: $themeFont;
          font-weight: bold;
          height: 45px;
          width: 100%;
          border: 2px solid $themeLightGrey;
          border-radius: 8px;
          background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
            96% no-repeat $themeWhite;
        }
      }
    }

    
  }

  &__content {
    padding-bottom: 25px;

    &-subtitle {
      color: $themeBlue;
      font-family: $themeTitleFont;
      font-size: 1.8em;
      padding-top: 60px;
    }
  }
}
