@import "../../assets/shared";

@mixin achivevementSectionSpan {
  font-family: $themeTitleFont;
  color: $themeBlue;
  font-size: 1.3em;
  display: inline-block;
}

.achievements-page {
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-bottom: 20px;

  .header {
    @include flexColumn;
    padding: 25px;
    padding-bottom: 0px;
    padding-top: 0px;

    &__title {
      font-family: $themeTitleFont;
      color: $themeBlue;
      font-size: 3em;

      &--sub {
        color: $themeLightBlue;
        font-family: $themeFont;
        font-size: 1em;
        font-weight: bold;
      }
    }
  }

  .main-container {
    @include flexColumn;
    padding: 25px;

    .achievements {
      @include flexColumn;

      &__settings {
        @include flexColumn;
        border: 1px solid $themeGrey;
        border-radius: 8px;
        padding: 25px;
        margin-bottom: 20px;

        span {
          @include achivevementSectionSpan;
        }
      }

      &__sharing {
        @include flexColumn;
        border: 1px solid $themeGrey;
        border-radius: 8px;
        padding: 25px;
        flex-grow: 1;
        max-width: 100%;

        @include for-desktop-up {
          max-width: 600px;
        }

        @include for-big-desktop-up {
          max-width: 800px;
        }

        span {
          @include achivevementSectionSpan;
        }
      }

      &__wrapper {
        @include flexRow;
        width: 100%;
        justify-content: space-around;
      }

      &__small {
        @include flexRow;
        justify-content: space-between;
        margin-top: 20px;
      }
    }

    .achievement {
      @include flexColumn;
      background-color: $themeLightGrey;
      border-radius: 8px;
      padding: 10px;
      width: 180px;
      height: 220px;
      margin: 5px;

      .wrapper {
        text-align: center;
        width: 100%;
        height: 100%;

        span {
          font-family: $themeFont;
          font-size: 0.8em;
          color: $themeBlue;
          font-weight: bold;
          display: inline;
        }
      }
    }
  }

  .group-title {
    margin: 5px;
    padding: 10px;
    margin-top: 0px;
    padding-top: 0px;
    font-size: 1.3em;
  }
}
