@import "../../assets/shared";

.dashboard-page {
  display: flex;
  flex-direction: column;
  min-width: 1000px;

  &__content {
    padding: 40px;

    .flex-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 40px;
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &_text {
        display: flex;
        flex-direction: column;

      }

      &_select {
        font-size: 1em;
        color: $themeBlue;
        font-family: $themeFont;
        font-weight: bold;
        min-width: 25%;

        .dropdown-label {
          padding: 10px 0;
        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 0px 10px 0px 10px;
          font-size: 0.875em;
          font-family: $themeFont;
          font-weight: bold;
          height: 45px;
          width: 100%;
          border: 2px solid $themeLightGrey;
          border-radius: 8px;
          background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
            96% no-repeat $themeWhite;
          color: $themeBlue;
        }
      }
    }
  }
}

.sub-header {
  padding: 20px;
  width: 100%;
  height: 56px;
  background-color: $themeLightBlue;
  color: $themeWhite;
  border-left: 4px solid $themeWhite;
}

.footer_wrapper {
  padding: 25px;
}
