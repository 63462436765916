.team-member-creation {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;

  table {
    min-width: 90%;
    td {
      margin-right: 0.5em;
    }
  }
}
