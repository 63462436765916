@import "../../../../assets/shared";

.reports-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__users {
    display: flex;
    flex-direction: row;

    &-info {
      background-color: $themeLightGrey;
      color: $themeBlue;
      font-weight: bold;
      padding: 10px;
      margin-left: 1px;
    }
  }

  &__scale {
    display: flex;
    flex-direction: row;

    &-info {
      display: flex;
      align-items: center;
      color: $themeBlue;
      padding: 10px;

      &-dot {
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }

      &-label {
        padding: 10px;
      }
    }
  }

  .left {
    border-radius: 10px 0 0 10px;
  }

  .right {
    border-radius: 0 10px 10px 0;
  }
}
