@import "../../assets/shared";

.view-only-user-dialog {
  &__backdrop {
    background-color: $themeLightGrey !important;
    opacity: 0.9 !important;
  }

  &__close-container {
    display: flex;
    justify-content: flex-end;
    background-color: $themeTransparent;
  }

  &__close-button {
    font-family: $themeFont;
    font-size: 0.875em;
    color: $themeWhite;
    background-color: $themeLightBlue;
    border: none;
    border-radius: 10px 10px 0 0;
    padding: 10px 15px 10px 15px;

    i {
      margin-left: 10px;
    }
  }

  &__container {
    background-color: $themeLightBlue;
  }

  &__content {
    background-color: $themeWhite;
    border-radius: 20px;
    padding: 40px;

    @include for-tablet-portrait-up {
      padding: 40px 80px;
    }

    &-title {
      display: flex;
      justify-content: center;
      font-size: 1.75em;
      font-family: $themeTitleFont;
      color: $themeBlue;
      padding-bottom: 10px;
    }

    &-sub-title {
      display: flex;
      justify-content: center;
      font-weight: bold;
      color: $themeLightBlue;
    }

    &-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 20px;

      label {
        display: inline-block;
        color: $themeBlue;
        font-size: 0.875em;
        font-weight: bold;
        width: 100%;

        @include for-tablet-portrait-up {
          width: 35%;
        }
      }

      input {
        display: inline-block;
        height: 30px;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        padding: 20px 10px 20px 10px;
        font-family: $themeFont;
        font-size: 0.875em;
        font-weight: bold;
        color: $themeBlue;

        @include for-tablet-portrait-up {
          width: 65%;
        }
      }

     .select {
        display: inline-block;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        padding: 10px 10px;
        font-family: $themeFont;
        font-size: 0.875em;
        font-weight: bold;
        color: $themeBlue;
        line-height: 30px;

        @include for-tablet-portrait-up {
          width: 65%;
        }
      }

      .dropdown-label {
        display: inline-block;
        color: $themeBlue;
        font-size: 0.875em;
        font-weight: bold;
        width: 100%;

        @include for-tablet-portrait-up {
          width: 35%;
        }
      }

      .validation-message {
        height: 25px;
        font-size: 0.8em;
        color: $themeOrange;
        text-align: right;
      }
    }

    &-actions {
      display: flex;
      justify-content: space-around;
      padding: 40px 0 20px 0;

      &-submit,
      &-regular,
      &-cancel,
      &-danger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 150px;
        color: $themeWhite;
        border: none;
        border-radius: 10px;
        padding: 15px;
        font-family: $themeFont;
        font-size: 1em;
        font-weight: bold;
      }

      &-submit {
        background-color: $themeGreen;
        width: 200px;
      }

      &-regular {
        background-color: $themeLightBlue;
        width: 200px;
      }

      &-cancel {
        background-color: $themeLightBlue;
        justify-content: flex-start;

        i {
          margin-right: 10px;
        }
      }

      &-danger {
        background-color: $themeOrange;
      }
    }
  }
}
