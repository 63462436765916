@import "../../../../assets/shared";

.top10-page {
  &__header {
    &_select {
      font-size: 1em;
      color: $themeBlue;
      font-family: $themeFont;
      font-weight: bold;
      min-width: 25%;

      .dropdown-label {
        padding: 10px 0;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0px 10px 0px 10px;
        font-size: 0.875em;
        font-family: $themeFont;
        font-weight: bold;
        height: 45px;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) 96% no-repeat $themeWhite;
      }
    }

    &_subheader {
      display: flex;
    }
  }

  &__content {
    padding: 25px 0;
  }
}