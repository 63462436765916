@import "../../../../assets/variables.scss";

$border: solid 2px $themeLightGrey;

ul.team-wizard-steps {
    border-top: $border;
    border-bottom: $border;

    list-style: none;
    padding: 1rem 0; // inner spacing
    margin: 2rem 0 15rem; // outer spacing

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > li {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        font-size: 1.25rem;

        color: $themeGrey;

        &.team-wizard-step--active {
            color: $themeBlue;
        }

        > div {
            > i {
                margin-right: 0.5rem;
            }
        }

        > i.team-wizard-step-arrow {
            margin: 0 1rem;
            font-size: 1rem;
        }

        &:last-child {
            > i.team-wizard-step-arrow {
                display: none;
            }
        }
    }
}