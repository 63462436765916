@keyframes pageLoadingFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pageLoadedFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  animation: pageLoadingFadeIn 1s linear;

  .page-loading__message {
    margin-top: 15px;
    font-size: 1.5em;
  }
}

.page-loaded {
  height: 100%;
  animation: pageLoadedFadeIn 1s;
}