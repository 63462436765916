@import "../../../../assets/shared";

.bargraph {
  display: flex;

  &__bar {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 120px;

    &-container {
      display: flex;
      align-items: flex-end;
      flex: 1;
      background-color: $themeLightGrey;
      margin: 0 2px;
    }

    &-description {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }

    &-value {
      flex: 1;
      background-color: $themeLightBlue;
    }
  }
}
