@import "../../assets/shared";

.questionnaire-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__sub-header {
    margin-top: 65px;
    width: 100%;
    background-color: $themeLightBlue;
    color: $themeWhite;
    padding: 20px;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 100%;
    padding: 40px 40px 0 40px;

    @include for-tablet-landscape-up {
      width: 80%;
    }

    @include for-desktop-up {
      width: 65%;
    }

    &-header {
      border-bottom: 2px solid $themeGrey;
      width: 100%;

      &-title {
        display: flex;
        font-family: $themeTitleFont;
        color: $themeBlue;
        padding-bottom: 20px;

        &-main {
          font-size: 2em;

          &-final {
            font-family: $themeFont;
            font-weight: bold;
            font-size: 1.2em;
            text-align: center;
          }
        }

        &-pre {
          font-size: 3em;
        }
      }

      &-sub-title {
        font-size: 1.2em;
        color: $themeDarkGrey;
        font-family: $themeFont;
      }
    }

    &-start {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      padding: 15px;
      border-radius: 10px;
      background-color: $themeLightGrey;
      color: $themeBlue;
      font-weight: bold;
      width: 100%;

      &-label {
        font-size: 1.2em;
      }

      @include for-tablet-landscape-up {
        width: 80%;
      }

      &-actions {
        display: flex;
        flex-direction: row;

        &-no,
        &-yes {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          border: none;
          border-radius: 10px;
          padding: 15px;
          background-color: $themeWhite;
          color: $themeBlue;
          font-family: $themeFont;
          font-weight: bold;

          span {
            font-size: 1.4em;
          }
          i {
            font-size: 1.3em;
          }
        }

        &-yes {
          background-color: $themeGreen;
          color: $themeWhite;
          margin-left: 20px;
        }

        &-tm-btn {
          border: none;
          padding: 15px 32px;
          text-align: center;
          font-size: 16px;
          margin: 4px 2px;
          background-color: $themeGreen;
          color: $themeWhite;
          font-family: $themeFont;
          font-weight: bold;
          text-decoration: none;
          border-radius: 3px;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 20px;

      @include for-tablet-landscape-up {
        width: 80%;
      }

      &-question {
        display: flex;
        align-items: center;
        align-self: flex-start;

        span {
          font-size: 1.35em;
          font-weight: bold;
          color: $themeBlue;
          padding-left: 10px;
        }

        span:last-child {
          padding-left: 16px;
        }

        i {
          font-size: 2.5em;
          color: $themeLightBlue;
          background-color: $themeWhite;
        }
      }

      &-explanation {
        display: flex;
        align-items: center;
        align-self: flex-start;

        span {
          font-size: 1.1em;
          color: $themeBlue;
          padding-left: 10px;
        }
      }

      &-opentext {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 50%;
        padding: 10px 0 10px 0;

        textarea {
          background-color: $themeLightGrey;
          font-family: $themeFont;
          font-weight: bold;
          font-size: 1.25em;
          color: $themeBlue;
          resize: none;
          border: none;
          border-radius: 10px;
          padding: 10px;
          width: 100%;
          height: 100%;
        }

        textarea::placeholder {
          color: $themeBlue;
        }

        &-tip {
          padding: 5px 0 0 5px;
          font-size: 0.875em;
          color: $themeBlue;
        }
      }

      &-threeinputs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 50%;
        padding: 10px 0 10px 0;

        input {
          background-color: $themeLightGrey;
          font-family: $themeFont;
          font-weight: bold;
          font-size: 1.25em;
          color: $themeBlue;
          resize: none;
          border: none;
          border-radius: 10px;
          padding: 10px;
          margin: 10px 0px;
        }

        input::placeholder {
          color: $themeBlue;
        }

        &-tip {
          padding: 5px 0 0 5px;
          font-size: 0.875em;
          color: $themeBlue;
        }
      }

      &-options {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-container {
          padding: 20px;
        }

        &-title {
          font-size: 1.2em;
          font-weight: bold;
          color: $themeDarkGrey;
        }

        &-scale {
          display: flex;
          flex-direction: row;
          padding: 20px 0;

          &-item,
          &-item-active {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            width: 50px;
            border: none;
            border-radius: 10px;
            padding: 5px;
            background-color: $themeGrey;
            color: $themeBlue;
            font-family: $themeFont;
            font-weight: bold;
            font-size: 1.5em;
            margin-left: 10px;

            @include for-tablet-portrait-up {
              padding: 15px;
            }
          }

          &-item-active {
            background-color: $themeLightBlue;
            color: $themeWhite;
          }

          &-item:first-child,
          &-item-active:first-child {
            margin-left: 0;
          }
        }

        &-single-choice {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .checkmark:after {
              display: block;
            }
          }

          input:hover ~ .checkmark {
            background-color: #ccc;
          }

          input:checked ~ .checkmark {
            background-color: $themeLightBlue;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border-radius: 50%;

            :after {
              top: 9px;
              left: 9px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: white;
              content: "";
              position: absolute;
              display: none;

              input:checked {
                display: block;
              }
            }
          }
        }

        &-footer {
          display: flex;
          justify-content: space-between;
          font-size: 0.875em;
          font-weight: bold;
          color: $themeDarkGrey;
        }
      }

      &-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-back,
        &-next {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 200px;
          border: none;
          border-radius: 10px;
          padding: 15px;
          background-color: $themeGrey;
          color: $themeBlue;
          font-family: $themeFont;
          font-weight: bold;
          font-size: 1.2em;
        }

        &-next {
          background-color: $themeGreen;
          color: $themeWhite;
        }

        &-separator {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          hr {
            width: 100%;
            border: 1px solid $themeGrey;
          }
        }
      }

      &-description {
        color: $themeBlue;
      }

      &-error {
        color: $themeOrange;
      }
    }
  }

  &__content-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 65px;
  }

  &__footer {
    margin-bottom: 10px;
    padding: 25px;
  }

  .no-content {
    font-family: $themeFont;
    font-weight: bold;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .social-icons {
    text-align: center;
  }

  .social-icons li {
    display: inline-block;
    list-style-type: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .social-icons li a {
    border-bottom: none;
  }

  .social-icons li img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .anim {
    animation: 0.3s ease-in 0s 1 fadeIn;
  }
}
