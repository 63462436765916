@import "../../assets/shared";

.input {
  display: inline-block;
  height: 30px;
  width: 100%;
  border: 2px solid $themeLightGrey;
  border-radius: 8px;
  padding: 20px 10px 20px 10px;
  font-family: $themeFont;
  font-size: 0.875em;
  font-weight: bold;
  color: $themeBlue;
  min-width: 125px;
}
