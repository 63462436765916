@import "../../../../assets/shared";

.review-table {
  color: $themeBlue;
  border-spacing: 0px !important;
  
  &__header{
  th {
      border-bottom: 2px solid $themeLightBlue;
      padding: 1vw 11vw 1vw 0;
      text-align: left !important;
      i {
        margin-left: 0.5rem;
      }
  }
}

  &__body{

    td {
      text-align: left;
      padding: 1em 0em;
      border-bottom: 1px solid $themeLightGrey;

      i {
        padding: 0em 0.8em;
      }
    }

    .fa-edit {
        color: $themeLightBlue;
        cursor:pointer;
      }

      .fa-trash {
        color: $themeOrange;
        cursor:pointer;
      }
      .fa-paper-plane {
        color: $themeGreen;
        cursor:pointer;
      }
  }
}