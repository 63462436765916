@import "../../../../assets/shared";

.profile-page {
  &__content {
    flex: 1;
    padding: 40px 20px;

    @include for-tablet-portrait-up {
      padding: 40px;
    }
  }

  &__reseller {
    display: flex;
    justify-content: space-between;
    min-height: 60px;

    >div {
      width: 50%;
    }

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      width: 45%;
      padding: 5px 10px;
      font-family: $themeFont;
      color: $themeWhite;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 5px;
      border: none;
    }

    &_input {
      height: 30px;
      width: 100%;
      border: 2px solid $themeLightGrey;
      border-radius: 8px;
      padding: 20px 10px;
      font-family: $themeFont;
      font-size: 0.875em;
      font-weight: bold;
      color: $themeBlue;
    }

    &_add {
      background-color: $themeDarkBlue;
    }

    &_remove {
      background-color: $themeOrange;
    }
  }

  &__forms {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 40px;

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include for-tablet-landscape-up {
        width: 45%;
      }

      label {
        display: inline-block;
        color: $themeBlue;
        font-size: 0.875em;
        font-weight: bold;
        width: 100%;

        @include for-tablet-landscape-up {
          width: 50%;
        }
      }

      &_input {
        display: inline-block;
        height: 30px;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        padding: 20px 10px 20px 10px;
        font-family: $themeFont;
        font-size: 0.875em;
        font-weight: bold;
        color: $themeBlue;

        @include for-tablet-landscape-up {
          width: 50%;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0px 10px 0px 10px;
        font-size: 0.875em;
        font-family: $themeFont;
        font-weight: bold;
        height: 45px;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) 96% no-repeat $themeWhite;

        @include for-tablet-landscape-up {
          width: 50%;
        }
      }

      &-title {
        margin-bottom: 30px;
        font-weight: bold;
        color: $themeLightBlue;
        padding: 10px 0 10px 0;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: $themeLightGrey;
    border-radius: 10px;
    padding: 20px;

    @include for-tablet-portrait-up {
      flex-wrap: nowrap;
    }

    &-label {
      flex: auto;
      font-weight: bold;
      color: $themeBlue;
      padding: 10px;
    }

    &-button {
      width: 250px;
      padding: 10px;

      .save-button {
        height: 45px;
        width: 180px;
        border-radius: 10px;
        background-color: $themeLightBlue;
        color: $themeWhite;
        font-family: $themeFont;
        font-size: 0.875em;
        font-weight: bold;
        text-align: left;
        padding-left: 10px;
        outline: 0;
        border: none;

        i {
          position: relative;
          left: 40px;
          font-size: 1em;
        }
      }
    }
  }

  .dropdown-label {
    display: inline-block;
    width: 100%;
    color: $themeBlue;
    font-size: 0.875em;
    font-weight: bold;

    @include for-tablet-landscape-up {
      width: 50%;
    }
  }

  .validation-message {
    height: 25px;
    font-size: 0.8em;
    color: $themeOrange;
    text-align: right;
  }

  .error-message {
    height: 30px;
    width: 90%;
    margin-top: 10px;
    color: red;
    font-size: 0.875em;
  }

  .checkbox-label {
    width: 100%;
    color: $themeBlue;
    font-size: 0.625em;
    margin-top: 12px;
  }

  .help-button {
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-left: 10px;
    background-color: $themeLightBlue;
    color: $themeWhite;
    border-radius: 50%;
    padding-left: 5px;
    border: none;

    i {
      font-size: 1.5em;
    }
  }

  .container {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 1.375em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 45px;

    input:checked~.checkmark:after {
      display: block;
    }

    .checkmark {
      position: absolute;
      border: 2px solid $themeLightGrey;
      border-radius: 8px;
      top: 0;
      left: 0;
      height: 45px;
      width: 45px;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 15px;
      top: 8px;
      width: 10px;
      height: 20px;
      border: solid $themeBlue;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .checkmark {
    margin-left: calc(100% - 45px);

    @include for-tablet-landscape-up {
      margin-left: 100%;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: $themeBlue;
    color: $themeWhite;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    font-size: 0.875em;
    position: absolute;
    top: -45px;
    left: 120%;

    @include for-tablet-landscape-up {
      top: 40px;
      left: -350%;
    }
  }

  .tooltip .tooltiptext::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 100%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $themeBlue transparent transparent;

    @include for-tablet-landscape-up {
      top: -10%;
      right: 25%;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
