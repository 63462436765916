@import "../../../../assets/shared";

.compare-graph-container {
  border: 1px solid $themeLightGrey;
  padding: 8px 20px 20px 20px;
  border-radius: 12px;
  .compare-graph-header {
    display: flex;
    align-items: center;
    .subtitle {
      flex-grow: 1;
      text-align: right;
      margin-right: 16px;
    }
    i {
      padding: 16px;
    }
  }
  .comparison-container {
    display: flex;
    .graph-bar-container {
      flex-grow: 1;
      .graph-bar {
        width: 100%;
        background-color: $themeGrey;
        height: 12px;
        z-index: 1;
        position: relative;
        &::before {
          height: 12px;
          width: 100%;
          background: linear-gradient(
            to right,
            $themeWhite 2px,
            transparent 2px
          );
          background-size: 10%;
          content: "";
          position: absolute;
          z-index: 1;
          left: 0;
        }
        span.bar {
          height: 12px;
          content: "";
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
        }
        span.line {
          background-color: $themeBlack;
          height: 54px;
          width: 2px;
          position: absolute;
          content: "";
          z-index: 3;
        }
      }

      .up-bar {
        span.line {
          top: -8px;
        }
      }
      .bottom-bar {
        span.line {
          top: -32px;
        }
      }
      .graph-bar-compare-divisor {
        display: flex;
        align-items: center;
        .ghost-bar-start {
          height: 12px;
        }
        .line-between {
          height: 2px;
          background-color: $themeBlack;
          flex-grow: 1;
        }
        .ghost-bar-end {
          height: 12px;
        }
      }
    }
    .score-container {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        font-family: "Omnes-Medium";
        color: $themeWhite;
        margin-left: 16px;
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.high {
  background-color: $themeGreen;
}
.low {
  background-color: $themeOrange;
}
.medium {
  background-color: $themeYellow;
}
