@font-face {
  font-family: 'Omnes-Regular';
  src: local('Omnes-Regular'), url('./assets/fonts/Omnes/omnes-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes-Medium';
  src: local('Omnes-Medium'), url('./assets/fonts/Omnes/omnes-medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}