@import "../../../../assets/shared";



  .header {
    display: flex;
    flex-direction: column;

    &-select {
      font-size: 1em;
      color: $themeBlue;
      font-family: $themeFont;
      font-weight: bold;
      min-width: 20%;

      .dropdown-label {
        padding: 10px 0;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0px 10px 0px 10px;
        font-size: 0.875em;
        font-family: $themeFont;
        font-weight: bold;
        height: 45px;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) 96% no-repeat $themeWhite;
      }
    }

    &__filters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-buttons {
        display: flex;
        flex-direction: row;
        border-radius: 6px;
        min-width: 50%;
        align-items: center;
        justify-content: flex-end;

        .button {
          background-color: $themeLightGrey;
          color: $themeBlue;
          font-family: $themeFont;
          font-weight: 600;
          padding: 15px;
          margin-left: 1px;
          cursor: pointer;

          &:hover {
            background-color: $themeGrey;
            color: $themeBlue;
          }

          &.active {
            background-color: $themeBlue;
            color: $themeWhite;
          }
        }

        :first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        :last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    &-title {
      font-family: $themeTitleFont;
      color: $themeBlue;
      font-size: 3em;
    }

    &__intro-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

  }

  .comment-wrapper {
    display: flex;
    flex-direction: column;
  }

  .comment {
    display: flex;
    flex-direction: row;
    border: 1px solid $themeGrey;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 15px;
    word-break: break-word;

    &__text {
      font-family: $themeFont;
      font-weight: 600;
      color: $themeDarkGrey;
    }

    &__icon {
      font-size: 4em;
      color: $themeBlue;
      padding: 25px;
      padding-left: 0px;
    }
  }
