@import "../../assets/shared";

.page-title {
  padding: 20px 40px 20px 40px;
  display: flex;

  &__height {
    min-height: 280px;
  }

  @media screen and (max-width: 1200px) {
    &__height {
      min-height: 300px;
    }
  }

  @media screen and (max-width: 1200px) {
    &__height {
      min-height: 360px;
    }
  }

  &__title {
    font-size: 2.5em;
    font-family: $themeTitleFont;
    color: $themeBlue;
    margin-bottom: 20px;
  }

  &__sub-title {
    padding: 0 0 0 5px;
    color: $themeLightBlue;
    font-weight: bold;
    line-height: 1.2rem;
  }

  &_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .title-box {
    flex-grow: 1;
  }
}

.sub-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paragraphs {
  padding-left: 5px;
  text-align: justify;
  flex: 0.8;

  &__icon {
    font-size: 4em;
    color: $themeBlack;
    padding: 0 25px 0px 0px;
  }
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
