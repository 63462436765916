@import "../../../../assets/shared";

.profile-progress-bar {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-bottom: 2px solid $themeLightGrey;

  &__label-left {
    padding-bottom: 10px;
    font-size: 0.875em;
    font-weight: bold;
    color: $themeBlue;

    span {
      font-size: 1.5em;
    }
  }

  &__bar {
    height: 10px;
    background-color: $themeLightGrey;
    border-radius: 5px;
  }

  &__bar > div {
    background-color: $themeLightBlue;
    height: 10px;
    border-radius: 5px;
  }

  &__description {
    padding-top: 10px;
    color: $themeLightBlue;
    font-weight: bold;
  }
}
