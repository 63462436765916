@import "../../assets/shared";

.tabbar-v2 {
  display: flex;
  margin-bottom: 20px;

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    border-bottom: 2px solid $themeBlue;
    font-family: $themeTitleFont;
    font-size: 1.2em;

    li:last-child {
      margin: 0px;
    }
  }

  li {
    width: 260px;
    background-color: $themeLightGrey;
    color: $themeBlue;
    margin-right: 15px;
    border-radius: 10px 10px 0 0;
    height: 50px;
  }

  li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    padding: 0 10px;
    text-decoration: none;
    height: 100%;
    border-radius: 10px 10px 0 0;

    span {
      padding: 0 5px;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .metric {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      height: 42px;
      width: 42px;
      color: $themeWhite;
      font-family: $themeFont;
      background-color: $themeLightBlue;
    }
  }

  li a:hover {
    background-color: $themeGrey;
    color: $themeBlue;
  }

  li.active,
  li.active a:hover {
    background-color: $themeBlue;
    color: $themeWhite;
  }

  li.red a {
    border: 2px solid $themeOrange;
    border-bottom: 0;
  }

  li.green a {
    border: 2px solid $themeGreen;
    border-bottom: 0;
  }

  li.blue a {
    border: 2px solid $themeLightBlue;
    border-bottom: 0;
  }
}
