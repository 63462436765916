@import "../../../../assets/shared";

.reports-card {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  border: 1px solid $themeLightGrey;
  &.no-border {
    border: 0 !important;
  }
  border-radius: 10px;
  width: 100%;
  i {
    font-size: 28px;
    color: $themeLightGrey;
    &.selected {
      color: $themeBlue !important;
    }
  }
  &__body {
    display: flex;
    flex-direction: row;
    h6,
    p {
      margin: 0;
      padding: 0;
    }
    i {
      color: $themeLightBlue !important;
    }
  }
  button {
    background-color: transparent;
    border: 0;
  }
}
