@import "../../assets/shared";



  .video-title {
    color: $themeBlue;
    font-weight: bold;
    font-size: 1.2em;
    padding: 20px 0;
  }

  .video-container {
    @include flexRow;
    justify-content: space-around;
    align-items: center;
    padding: 30px;

    .video-wrapper {
      @include flexColumn;
      align-items: center;
      border: 1px solid $themeGrey;
      border-radius: 6px;
      height: 350px;
      width: 350px;
      margin: 10px;

      .video {
        width: 100%;
        height: 80%;
        border: none;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      span {
        color: $themeBlue;
        font-family: $themeFont;
        text-align: center;
        font-weight: bold;
        font-size: 1em;
        padding: 10px;
      }
    }
  }

