@import "../../assets/shared";

.table-footer {
  display: flex;
  align-items: center;
  padding: 20px;
  color: $themeBlue;
  font-weight: bold;

  td {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__reset {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: $themeOrange;
    font-weight: bold;
    font-family: $themeFont;
    color: $themeWhite;
    border: none;
    border-radius: 10px;
    font-size: 1em;
  }

  &__pagination {
    &-button {
      border: none;
      color: $themeBlue;
      font-weight: bold;
      font-size: 1.5em;
      margin-right: 10px;
    }
  }

  &_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 16px;

    .actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
    }

    .table_form_actions {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: flex-end;
    }

    .add {
      display: flex;
      gap: 8px;
      background-color: $themeGreen;
      color: $themeWhite;
      border: none;
      border-radius: 10px;
      padding: 15px;
      font-weight: bold;
      font-family: $themeFont;
      font-size: 1em;
    }

    .submit {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      background-color: $themeLightBlue;
      color: #fff;
      border: none;
      border-radius: 10px;
      padding: 15px;
      font-family: $themeFont;
      font-size: 1em;
      font-weight: bold;
    }

    .payment {
      display: flex;
      gap: 8px;
      width: fit-content;
      justify-content: space-between;
      align-items: center;
      background-color: $themeGreen;
      color: #fff;
      border: none;
      border-radius: 10px;
      padding: 15px;
      font-family: $themeFont;
      font-size: 1em;
      font-weight: bold;
    }
  }
}
