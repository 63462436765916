@import "~font-awesome/css/font-awesome.min.css";
@import "mixins";
@import "variables";

body {
  margin: 0;
  font-family: $themeFont;
}

html,
body,
#root,
.app {
  display: flex;
  flex-direction: column;
  height: 100%;

  input,
  select,
  option,
  textarea {
    font-family: inherit;
  }

  button,
  select,
  input[type="checkbox"] {
    cursor: pointer;
  }

  .blur {
    filter: blur(10px);
  }

  .high {
    background-color: $themeGreen;
  }

  .low {
    background-color: $themeOrange;
  }

  .medium {
    background-color: $themeYellow;
  }

  .fadeIn {
    animation: fadeIn 1s linear;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.app__content {
  display: flex;
  flex-direction: row;
  flex: 1;

  .settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .settings__content {
      width: 100%;
      height: 100%;
    }
  }
}

.layout__content {
  width: 100%;
  margin-top: 65px;
  margin-left: 60px;

  @include for-tablet-landscape-up {
    margin-left: 190px;
  }
}

.pointer{
  cursor: pointer;
}

/* CONTENTS */
.page-content {
  width: 100%;

  .header {
    padding: 25px;
    padding-bottom: 0px;
    padding-top: 0px;

    &__row {
      display: flex;
      flex-direction: row;

      div:first-child {
        background-color: red;
        flex-grow: 1;
      }
    }

    &__column {
      @include flexColumn;
    }

    &__title {
      font-family: $themeTitleFont;
      color: $themeBlue;
      font-size: 3em;

      &--sub {
        color: $themeLightBlue;
        font-family: $themeFont;
        font-size: 1em;
        font-weight: bold;
      }
    }
  }
}

/* FONT STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-family: "PT Serif", serif;
  color: #173b59;
  font-weight: 300;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 2.3em;
}

h4 {
  font-size: 1.8em;
}

h5 {
  font-family: $themeFont;
  font-size: 1.5em;
  letter-spacing: 0.035em;
  line-height: 1.5em;

  &.divisor {
    border-bottom: 1px solid $themeBlue;
    line-height: 3em;
    color: $themeBlue;
  }
}

h6 {
  font-family: $themeFont;
  font-size: 1.1em;
  letter-spacing: 0.035em;
  line-height: 1.3em;
}

/*  SPACING */
.m-1 {
  margin: 4px;
}

.m-2 {
  margin: 8px;
}

.m-3 {
  margin: 12px;
}

.m-4 {
  margin: 24px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 24px;
}

.mb-4_5 {
  margin-bottom: 36px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 24px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 24px;
}
.mt-4_5{
 margin-top: 36px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-4 {
  margin-right: 24px;
}

.mv-1 {
  margin: 4px 0;
}

.mv-2 {
  margin: 8px 0;
}

.mv-3 {
  margin: 12px 0;
}

.mv-4 {
  margin: 24px 0;
}

.p-1 {
  padding: 4px;
}

.p-2 {
  padding: 8px;
}

.p-3 {
  padding: 12px;
}

.p-4 {
  padding: 24px;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-4 {
  padding-bottom: 24px;
}

.pl-1 {
  padding-left: 4px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-4 {
  padding-left: 24px;
}

.pt-1 {
  padding-top: 4px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 12px;
}

.pt-4 {
  padding-top: 24px;
}

.pr-1 {
  padding-right: 4px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-3 {
  padding-right: 12px;
}

.pr-4 {
  padding-right: 24px;
}

.pv-1 {
  padding: 4px 0;
}

.pv-2 {
  padding: 8px 0;
}

.pv-3 {
  padding: 12px 0;
}

.pv-4 {
  padding: 24px 0;
}

.display-none {
  display: none;
}

.h-full {
  height: 100%;
}

.max-h-120 {
  max-height: 120px;
}
