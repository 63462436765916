@import "../../assets/shared";

.bottom-info-container {
  padding: 20px;
  background-color: $themeLightGrey;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    border-radius: 8px;
    color: $themeWhite;
    font-family: $themeFont;
    font-size: 1.1em;
    border: 0;
    min-width: 200px;
    width: 30%;

    .button-content {
      padding: 16px;
      flex-wrap: nowrap;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &.blue {
      background-color: $themeBlue;
    }

    &.lightBlue {
      background-color: $themeLightBlue;
    }

    &.green {
      background-color: $themeGreen;
    }
  }
}
