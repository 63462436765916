@use "../../../../assets/shared" as shared;

@import "../../../../assets/variables.scss";

$border: solid 1px $themeLightGrey;

.step-size-selector{
    border: $border;
    max-width: 50em;
    margin:auto;
    border-radius: 0.5em;
    padding: 3em;
}

.step-size-selector-header{

    &__title {
        font-size: 1.5em;
        color: shared.$themeBlue;
        gap: 0.5rem;
        align-items: center;
        font-weight: bold;

        > i {
            margin-right: 0.625em;
        }
    }

    &__subTitle{
        padding: 0.625em 0 1.25em 2.2em;
        color: shared.$themeBlue;
        font-weight: bold;
    }
};

.step-size-selector-body__amount__wrapper{
    border: $border;
    display: flex;
    padding: 0.5em;
    border-radius: 0.5em;
    width: 8em;
    align-items: center;
    margin-right: 1em;
    background-color: white;

    &:focus-within{
        border-color: shared.$themeBlue;
    }
}

.step-size-selector-body__amount__buttons button{
    width: 0.9em;
    height: 0.75em;
    display: block;
    background-color: transparent;
    border:solid 1px transparent;
    font-size: 1.4em;
    outline:none;
    
    > i {
        height:0.75em;
        vertical-align: middle;
        margin-left: -0.15em;
    }

    &:first-child i {
        margin-top: -0.9em;
    }

    &:last-child i {
        margin-top: -1.15em;
    }

    &:active, &:focus {
        border-color:rgba(shared.$themeBlue, 0.1);
    }
}

.step-size-selector-body {
    margin-left: 2.2em;
    display: flex;
    align-items: stretch;
    
    &__amount{
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        font-size: 1.5em;
        border-right: $border;
        margin-right: 0.25em;
        outline: none;
    }
};

input[type="number"] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }