@use "../../assets/shared"as shared;

.page-content {
  padding: 00px 40px 20px 40px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
}
