@import "../../assets/shared";

.text-field {
  &:disabled {
    background-color: $themeSilver;
    color: $themeGrey;
  }
}

.numberic-input {
  text-align: right;
}
