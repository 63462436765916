@import "../../../../assets/shared";

.comments-compare-page {
  .comments-compare-page {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;


    .header {
      &__row {
        display: flex;
        flex-direction: row;

        div:first-child {
          background-color: red;
          flex-grow: 1;
        }
      }

      &__column {
        @include flexColumn;
      }

      padding: 25px;
      padding-bottom: 0px;
      padding-top: 0px;

      &__title {
        font-family: $themeTitleFont;
        color: $themeBlue;
        font-size: 3em;

        &--sub {
          color: $themeLightBlue;
          font-family: $themeFont;
          font-size: 1em;
          font-weight: bold;
        }
      }
    }
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__filters {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 12px;

      &-buttons {
        display: flex;
        flex-direction: row;
        border-radius: 6px;
        min-width: 50%;
        align-items: center;
        justify-content: flex-end;

        .button {
          background-color: $themeLightGrey;
          color: $themeBlue;
          font-family: $themeFont;
          font-weight: 600;
          padding: 15px;
          margin-left: 1px;
          cursor: pointer;

          &:hover {
            background-color: $themeGrey;
            color: $themeBlue;
          }

          &.active {
            background-color: $themeBlue;
            color: $themeWhite;
          }
        }

        :first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        :last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    &-select {
      font-size: 1em;
      color: $themeBlue;
      font-family: $themeFont;
      font-weight: bold;
      min-width: 220px;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0px 10px 0px 10px;
        font-size: 0.875em;
        font-family: $themeFont;
        font-weight: bold;
        height: 45px;
        width: 100%;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        width: 100%;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) 96% no-repeat $themeWhite;
      }
    }

    &__intro-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .comment-wrapper {
    display: flex;
    flex-direction: column;
  }

  .comment {
    display: flex;
    flex-direction: row;
    border: 1px solid $themeGrey;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 15px;
    word-break: break-word;

    &__icon {
      font-size: 4em;
      color: $themeBlue;
      padding: 25px;
      padding-left: 0px;
    }

    &__text {
      font-family: $themeFont;
      font-weight: 600;
      color: $themeDarkGrey;
    }
  }
}