@import "../../../../assets/shared";

.product-page {
  display: flex;
  flex-direction: column;
  min-width: 1000px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;

    &__info {
      display: flex;
      flex-direction: column;
      border: 2px solid $themeGrey;
      border-radius: 10px;
      width: 65%;
      padding: 20px;

      &-separator {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        color: $themeWhite;
        background-color: $themeLightBlue;
        font-family: $themeFont;
        font-size: 1em;
        border-radius: 4px;
        margin-bottom: 20px;
      }

      &-product {
        color: $themeBlue;
        margin-bottom: 20px;

        &-name {
          font-weight: 700;
        }

        label {
          display: inline-block;
          color: $themeBlue;
          font-size: 0.875em;
          font-weight: bold;
          width: 50%;
        }
      }

      &-customer {
        &_footer {
          display: flex;
          justify-content: flex-end;
          background-color: $themeLightGrey;
          padding: 10px;
        }

        label {
          display: inline-block;
          color: $themeBlue;
          font-size: 0.875em;
          font-weight: bold;
          width: 50%;
        }

        &_input {
          display: inline-block;
          height: 30px;
          width: 50%;
          border: 2px solid $themeLightGrey;
          border-radius: 8px;
          padding: 20px 10px 20px 10px;
          font-family: $themeFont;
          font-size: 0.875em;
          font-weight: bold;
          color: $themeBlue;
        }

        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 0px 10px 0px 10px;
          font-size: 0.875em;
          font-family: $themeFont;
          font-weight: bold;
          height: 45px;
          width: 50%;
          border: 2px solid $themeLightGrey;
          color: $themeBlue;
          border-radius: 8px;
          background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
            96% no-repeat $themeWhite;
        }

        .dropdown-label {
          display: inline-block;
          width: 50%;
          color: $themeBlue;
          font-size: 0.875em;
          font-weight: bold;
        }

        .error-message {
          margin-bottom: 10px;
          width: 100%;
          color: red;
          font-size: 0.875em;
        }

        .validation-message {
          height: 25px;
          font-size: 0.8em;
          color: $themeOrange;
          text-align: right;
        }
      }
    }

    &__order {
      width: 30%;
      padding: 20px;
      background-color: $themeLightGrey;
      color: $themeBlue;
      border-radius: 10px;
      height: 300px;

      &_price_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_header {
        display: flex;
        flex-direction: column;
      }

      &_title {
        font-size: 1.2em;
        font-weight: bold;
      }

      &_label {
        font-weight: bold;
      }

      &_value {
        font-size: 3em;
        font-family: $themeTitleFont;
      }

      &_coupon {
        display: flex;
        flex-direction: row;

        &-input {
          height: 30px;
          border: 2px solid $themeLightGrey;
          border-radius: 8px;
          padding: 20px 10px 20px 10px;
          font-family: $themeFont;
          font-size: 0.875em;
          font-weight: bold;
          color: $themeBlue;
          min-width: 0;
        }

        &-submit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 44px;
          padding: 10px;
          border: none;
          color: $themeWhite;
          background-color: $themeBlue;
          font-family: $themeFont;
          font-size: 1em;
          width: 100px;
          border-radius: 8px;
          margin-left: 20px;
        }

        &-error {
          color: $themeOrange;
        }
      }
    }
  }

  .header {
    display: flex;
    height: 56px;
    width: 100%;
    border-bottom: 2px solid $themeGrey;
    padding: 0 40px;

    &__button {
      color: $themeBlue;
      background-color: $themeWhite;
      text-align: center;
      border: none;
      width: fit-content;

      i {
        color: $themeLightBlue;
        font-size: 2em;
        vertical-align: middle;
        font-weight: bold;
      }

      span {
        font-size: 1.2em;
        font-weight: bold;
        font-family: $themeFont;
        color: $themeBlue;
        vertical-align: middle;
        padding: 15px;
      }
    }
  }

  .payment-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 2px solid $themeLightBlue;
    color: $themeWhite;
    background-color: $themeLightBlue;
    font-family: $themeFont;
    font-size: 1em;
    border-radius: 8px;
    width: 200px;
  }

  .title {
    font-family: $themeTitleFont;
    color: $themeBlue;
    font-size: 3em;
    padding: 40px;
  }
}
