@import "../../assets/shared";

.navbar {
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $shadow;
  background-color: $themeBlue;
  color: $themeWhite;
  height: 65px;
  width: 100%;
  position: fixed;
  top: 0;

  &__logo {
    display: flex;
    align-items: left;
    cursor: pointer;
    margin-left: 20px;

    img {
      height: 14px;
    }
  }

  &__help {
    display: flex;
    align-items: center;
    padding: 20px;
    background: darken($themeBlue, 2%);
    margin-left: 3px;
    cursor: pointer;

    span {
      padding-left: 10px;
      font-size: 0.875em;
    }

    i {
      font-size: 1.2em;
    }
  }
}
