@import "../../../../../../assets/shared";

.category-details-compare-page {
  min-width: 800px;

  &__content {

    &-comments {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 2px solid $themeLightGrey;
      border-radius: 10px;
      color: $themeDarkBlue;
      margin-top: 20px;

      &_description {
        margin-top: 15px;

        ul {
          margin-top: 0;
        }
      }

      &_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $themeTitleFont;
        font-size: 1.25em;

        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: $themeWhite;
          margin-right: 10px;
          width: 35px;
          height: 35px;
        }

        .green-background {
          background-color: $themeGreen;
        }

        .light-blue-background {
          background-color: $themeLightBlue;
        }

        .orange-background {
          background-color: $themeOrange;
        }
      }
    }

    &-description {
      color: $themeDarkBlue;
    }

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: $themeTitleFont;
      color: $themeBlue;
      margin-bottom: 40px;

      &-description {
        max-width: 65%;
        font-size: 3em;
      }
    }

    &-sub-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: $themeFont;
      color: $themeDarkGrey;
      margin-bottom: 40px;

      span {
        max-width: 65%;
      }
    }

    &-score {
      display: flex;
      flex-direction: row;
      border: 1px solid $themeBlue;
      margin-bottom: 20px;

      &-icon {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        min-width: 80px;

        img {
          max-height: 60px;
        }
      }

      &-value {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $themeLightBlue;
        color: $themeWhite;
        padding: 10px 20px;
        font-size: 2.5em;
        font-style: italic;
      }
    }

    &-options {
      min-width: 25%;

      &-label {
        margin-top: 20px;
        color: $themeBlue;
        font-weight: bold;
      }

      &-actions {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;

        select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border: none;
          background-color: $themeLightGrey;
          color: $themeBlue;
          font-family: $themeFont;
          font-size: 1em;
          font-weight: bold;
          border-radius: 5px;
        }
      }
    }
  }

  &__header {
    display: flex;
    height: 56px;
    width: 100%;
    background-color: $themeLightGrey;
    border-left: 4px solid $themeWhite;
    padding: 0 40px;

    &-button {
      background-color: transparent;
      color: $themeBlue;
      text-align: center;
      border: none;

      i {
        font-size: 2em;
        vertical-align: middle;
        font-weight: bold;
      }

      span {
        font-size: 1.2em;
        font-weight: bold;
        font-family: $themeFont;
        vertical-align: middle;
        padding: 15px;
      }
    }
  }
}
