@import "../../assets/shared";

.auth-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }

  &__form {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
    width: 100%;

    @include for-tablet-landscape-up {
      width: 65%;
    }

    input {
      display: block;
      height: 30px;
      width: 100%;
      border: 2px solid $themeLightGrey;
      border-radius: 8px;
      padding: 20px 10px 20px 10px;
      font-family: $themeFont;
      font-size: 0.875em;
      font-weight: bold;
    }

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0px 10px 0px 10px;
      font-size: 0.875em;
      font-family: $themeFont;
      font-weight: bold;
      height: 45px;
      width: 100%;
      border: 2px solid $themeLightGrey;
      border-radius: 8px;
      background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
        96% no-repeat $themeWhite;
    }

    &-logo {
      padding: 40px;

      img {
        height: 25px;
      }
    }

    &-progress {
      padding: 20px;
      text-align: center;

      img {
        height: 25px;
      }
    }

    &-redirect-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      background-color: $themeBlue;
      padding: 10px 15px;
      border-radius: 8px;

      .redirect-label {
        padding: 0 20px 0 0;
        color: $themeWhite;
      }

      .redirect-button {
        padding: 5px 20px;
        background-color: $themeWhite;
        color: $themeBlue;
        border-radius: 8px;
        font-size: 0.875em;
      }
    }

    &-fields {
      margin: auto;
      width: 80%;

      @include for-tablet-landscape-up {
        width: 65%;
      }

      &-title {
        font-size: 1.45em;
        font-weight: bold;
      }

      &-subtitle {
        font-size: 1em;
        padding: 20px 0 20px 0;
      }
    }

    &-buttons {
      .submit-button {
        width: 100%;
        padding: 10px 15px 10px 15px;
        font-size: 1em;
        color: $themeWhite;
        background-color: $themeLightBlue;
        text-align: center;
        border: none;
        border-radius: 8px;
        font-family: $themeFont;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          padding-right: 10px;
        }

        i {
          padding-bottom: 2.5px;
        }
        
      }

      .loader {
        border: 3px solid $themeLightGrey;
        border-radius: 50%;
        border-top: 3px solid $themeBlue;
        width: 30px;
        height: 30px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        margin: auto;
        margin-bottom: 20px;
      }

      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    &-footer {
      text-align: center;
      padding: 20px;
      display: flex;
      justify-content: center;
    }

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 1.375em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark {
        position: absolute;
        border: 2px solid $themeLightGrey;
        border-radius: 8px;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 10px;
        top: 5px;
        width: 8px;
        height: 14px;
        border: solid black;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .checkbox-description {
      font-size: 0.675em;
      font-weight: bold;
      height: 30px;
      padding: 7px 7px;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-tablet-landscape-up {
      width: 40%;
    }

    &-container {
      display: none;
      height: 100%;
      width: 100%;
      background-image: url("../../assets/images/background/background_image_1.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @include for-tablet-landscape-up {
        display: flex;
      }
    }
  }

  .error-message {
    margin-bottom: 10px;
    width: 100%;
    color: red;
    font-size: 0.875em;
  }

  .validation-message {
    height: 25px;
    font-size: 0.8em;
    color: $themeOrange;
    text-align: right;
  }

  .terms-link {
    display: block;
    font-size: 0.675em;
    text-align: center;
    color: $themeDarkGrey;
    padding: 0px 2px;
  }
}
